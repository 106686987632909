<template>
  <div class="pay-page">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>
        <div class="description">
          Oops, you have not specified a payment method or a current linked payment
          method is not verified and can not be used. You can set it up in your user profile.
        </div>
      </div>

      <div class="buttons">
        <router-link to="/user/edit" class="btn btn-form">
          Go to profile
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {

  async mounted() {

    if (!await this.checkUser())
      return;
  }

}
</script>

<style scoped>

.description {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 100px;
}

.buttons {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 30px;
}

.btn-form {
  max-width: 370px;
}

</style>